<template>
    <div class="layout-page">
        <div class="summary-container">
            <div class="summary-info">
                <div class="bills-generated">
                    <div class="title">
                        {{ $t('bills.bills_generated') }}
                    </div>
                    <div class="number">{{ Object.values(items).length }}</div>
                </div>
                <div class="bills-generated">
                    <div class="title">{{ $t('bills.clients') }}</div>
                    <div class="number" v-if="customers">{{ Object.values(customers).length }}</div>
                </div>
                <div class="bills-generated">
                    <div class="title">{{ $t('bills.import') }}</div>
                    <div class="number">{{ importMoney + '€' }}</div>
                </div>
            </div>
            <div class="summary-actions">
                <FilterBills v-on:applyFilters="resetSelectedItems()" v-on:resetFilters="resetSelectedItems()"></FilterBills>
                <div class="table-actions">
                    <!--
                    <div class="export">
                        <div class="export-button" @click="exportButton = !exportButton">{{ selected.length > 0 ? $t('bills.export') : $t('bills.export_all') }}</div>
                        <transition name="fade">
                            <div class="export-box" v-if="exportButton">
                                <div class="text" @click="downloadPDF()">{{ $t('bills.export_as_pdf') }}</div>
                                <div class="text" @click="downloadEXCEL()">{{ $t('bills.export_as_xlsx') }}</div>
                                <div class="text" @click="downloadXML()">{{ $t('bills.export_as_xml') }}</div>
                            </div>
                        </transition>
                    </div>
                    -->
                    <div class="search">
                        <input type="text" v-model="search" class="input-search" name="name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="data-table">
            <v-data-table @toggle-select-all="allSelected = !allSelected" v-if="items" v-model="selected" :headers="headers" fixed-header :search="search" :items="Object.values(items)" sort-by="name" :single-select="singleSelect" :sort-asc="true" hide-default-footer show-select :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                <template #item.ticket_number="item">
                    <div class="ticket">{{ '#' + item.item.ticket_number }}</div>
                </template>
                <template #item.corrective_number="item">
                    <div class="ticket">{{ '#' + item.item.corrective_number }}</div>
                </template>
                <template #item.date="item">
                    <div class="row">{{ moment(item.item.date * 1000).format('DD/MM/YYYY') }}</div>
                </template>
                <template #item.price="item">
                    <div class="row">{{ parseFloat(item.item.price).toFixed(2) + '€' }}</div>
                </template>
                <template #item.restaurant="item">
                    <div class="row">{{ restaurants(item.item.restaurant).code + ' - ' + restaurants(item.item.restaurant).name }}</div>
                </template>
                <template #item.customer_name="item">
                    <div class="row">{{ item.item.customer_name + ' - ' + item.item.customer_fiscal_id }}</div>
                </template>
                <template #item.file="item">
                    <div class="row-image" v-if="item.item.file">
                        <a target="_blank" :href="item.item.file"><img class="icon" src="../../public/img/pdf_icon.svg" /></a>
                    </div>
                </template>
                <template v-slot:no-data>
                    <div class="no-data">
                        {{ $t('bills.table_no_data') }}
                    </div>
                </template>
            </v-data-table>
            <div id="pagination">
                <div class="totalItems">
                    <span class="total">
                        <strong class="total-results">{{ Object.values(items).length }}</strong>
                        {{ $tc('bills.results', Object.values(items).length) }}
                    </span>
                </div>
                <v-pagination v-model="pagination.page" color="#C4C4C4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                <div class="rows-page">
                    <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FilterBills from '../components/layout/admin/bills/CorrectiveFilter.vue'
export default {
    components: { FilterBills },
    name: 'CorrectiveBills',
    data() {
        return {
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            allSelected: false,
            exportButton: false,
            singleSelect: false,
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            selected: [],
            maxPageVisibles: 10
        }
    },
    computed: {
        items() {
            const bills = this.$store.getters['bills/getCorrectiveBills']
            const sortedBills = Object.values(bills).sort((elementA, elementB) => {
                let result = 0
                if (elementA.date > elementB.date) result = -1
                if (elementA.date < elementB.date) result = 1
                if (elementA.date == elementB.date) result = this.sortBillNumber(elementA.ticket_number, elementB.ticket_number)
                return result
            })
            return sortedBills
        },
        nRegistros() {
            let letter = this.$t('bills.show_bills')
            let nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        headers() {
            return [
                {
                    text: this.$t('bills.corrective_invoice_number'),
                    align: 'start',
                    sortable: true,
                    sort: this.sortBillNumber,
                    value: 'ticket_number',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.corrective_number'),
                    align: 'start',
                    sortable: true,
                    sort: this.sortCorrectiveNumber,
                    value: 'corrective_number',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.corrective_date'),
                    value: 'date',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.import'),
                    value: 'price',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.restaurant'),
                    value: 'restaurant',
                    align: 'start',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('bills.user'),
                    value: 'customer_name',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'file',
                    align: 'start',
                    width: '6px',
                    sortable: false,
                    class: 'header-table'
                }
            ]
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        importMoney() {
            let number = 0
            if (this.items) {
                Object.values(this.items).forEach(function (numero) {
                    number += numero.price
                })
            }
            return parseFloat(number).toFixed(2)
        },
        customers() {
            let tempResult = {}
            if (this.items) {
                let arr = Object.values(this.items)

                for (let { customer_fiscal_id } of arr)
                    tempResult[customer_fiscal_id] = {
                        customer_fiscal_id,
                        count: tempResult[customer_fiscal_id] ? tempResult[customer_fiscal_id].count + 1 : 1
                    }

                let result = Object.values(tempResult)

                return result
            }
            return tempResult
        }
    },
    methods: {
        resetSelectedItems() {
            this.selected = []
            this.exportButton = false
        },
        restaurants(id) {
            if (id) {
                var restaurant = this.$store.getters['restaurant/getRestaurants'](id)
                if (restaurant) {
                    return restaurant
                }
            }
        },
        async load() {
            this.$store.dispatch('init')
            this.$store.dispatch('bills/loadFiltersBills')
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
.search {
    .input-search {
        background-image: url('../../public/img/searcher.svg');
    }
}
.cancel-invoice {
    font-weight: bold;
    background-color: #ffbc0d;
    padding: 4px 8px;
    border-radius: 4px;
    height: 30px;
    text-align: center;
}

.canceled {
    color: #b3b3b3;
}
</style>
